/* You can add global styles to this file, and also import other style files */
@import "./assets/tailwind.css";

@import '~@angular/cdk/overlay-prebuilt.css';

img {
  max-width: 100%;
}

pre {
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}
